.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; /* This will remove the scroll bar from the main page */
    box-sizing: border-box;
}

#root {
    height: 100%;
    width: 100%;
    overflow: hidden; /* This will remove the scroll bar from the main page */
}


.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


.loader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 6rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    opacity: 1;
    transition: opacity 1s linear;
}

.loader:before,
.loader:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    animation: pulsOut 1.8s ease-in-out infinite;
    filter: drop-shadow(0 0 1rem rgba(31, 75, 236, 0.75));
}

.loader:before {
    width: 100%;
    padding-bottom: 100%;
    box-shadow: inset 0 0 0 1rem rgba(31, 75, 236, 0.75);
    animation-name: pulsIn;
}

.loader:after {
    width: calc(100% - 2rem);
    padding-bottom: calc(100% - 2rem);
    box-shadow: 0 0 0 0 rgba(31, 75, 236, 0.75);
}

@keyframes pulsIn {
    0% {
        box-shadow: inset 0 0 0 1rem rgba(31, 75, 236, 0.75);
        opacity: 1;
    }
    50%, 100% {
        box-shadow: inset 0 0 0 0 rgba(31, 75, 236, 0.75);
        opacity: 0;
    }
}

@keyframes pulsOut {
    0%, 50% {
        box-shadow: 0 0 0 0 rgba(31, 75, 236, 0.75);
        opacity: 0;
    }
    100% {
        box-shadow: 0 0 0 1rem rgba(31, 75, 236, 0.75);
        opacity: 1;
    }
}
